import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import AboutMethod from "../views/AboutMethod.vue";
import Offer from "../views/Offer.vue";
import AboutUs from "../views/AboutUs.vue";
import Contact from "../views/Contact.vue";
import Impressum from "../views/Impressum.vue";
import NotFound from "../views/NotFound.vue";
import i18n from '../i18n';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: i18n.locale
  },
  {
    path: `/:lang(pl|de)`,
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
          title: 'Home'
        }
      },
      {
        path: "o-metodzie",
        name: "O metodzie",
        component: AboutMethod,
        meta: {
          title: 'O metodzie'
        }
      },
      {
        path: "oferta",
        name: "Oferta",
        component: Offer,
        meta: {
          title: 'Oferta'
        }
      },
      {
        path: "o-lektorze",
        name: "O lektorze",
        component: AboutUs,
        meta: {
          title: 'O lektorze'
        }
      },
      {
        path: "kontakt",
        name: "Kontakt",
        component: Contact,
        meta: {
          title: 'Kontakt'
        }
      },
      {
        path: "impressum",
        name: "Impressum",
        component: Impressum,
        meta: {
          title: 'Impressum'
        }
      },
      {
        path: "view-blog/:blogid",
        name: "ViewBlogPost",
        component: () => import(/* webpackChunkName: "viewBlogPost" */ '../views/ViewBlogPost.vue'),
        meta: {
          title: 'View Blog',
        },
      },
    ]
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: 'Not Found',
    }
  },
];


const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { 
      x: 0, y: 0,
      behavior: 'instant' 
    }
  },
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | AJ FULGUR`;
  next();
});

export default router;
