import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '../i18n';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: 'pl',
    welcomeScreen: {
      title: i18n.t(`welcomeScreen.title`),
      blogPost: i18n.t(`welcomeScreen.blogPost`),
      welcomeScreen: true,
      photo: "home-image",
    },
    contactCardsArray: [
      { chargeTitle: "", chargeName: "Damian Schwider", chargeContact: "+48 508 404 970", chargeMail: "akademia.jezykowa.schwider@gmail.com", chargePhoto: "damian-schwider" },
    ],
    blogPostArray: [
      {
        blogID: 0,
        blogTitle: i18n.t(`blogPostArray[0].blogTitle`),
        blogPreview: i18n.t(`blogPostArray[0].blogPreview`),
        blogCoverPhoto: "home-image",
        blogAdditionalPhotos: null,
      },
      {
        blogID: 1,
        blogTitle: i18n.t(`blogPostArray[1].blogTitle`),
        blogPreview: i18n.t(`blogPostArray[1].blogPreview`),
        blogHTML: [
          {
            chapterTitle: i18n.t(`blogPostArray[1].blogHTML[0].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[1].blogHTML[0].chapterHTML`),
          },
          {
            chapterTitle: i18n.t(`blogPostArray[1].blogHTML[1].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[1].blogHTML[1].chapterHTML`),
          },
          {
            chapterTitle: i18n.t(`blogPostArray[1].blogHTML[2].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[1].blogHTML[2].chapterHTML`),
          }
        ],
        blogCoverPhoto: "method-image",
        blogAdditionalPhotos: null,
        aboutMethod: true
      },
      {
        blogID: 2,
        blogTitle: i18n.t(`blogPostArray[2].blogTitle`),
        blogPreview: i18n.t(`blogPostArray[2].blogPreview`),
        blogHTML: [
          {
            chapterTitle: i18n.t(`blogPostArray[2].blogHTML[0].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[2].blogHTML[0].chapterHTML`),
          },
          {
            chapterTitle: i18n.t(`blogPostArray[2].blogHTML[1].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[2].blogHTML[1].chapterHTML`),
          },
          {
            chapterTitle: i18n.t(`blogPostArray[2].blogHTML[2].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[2].blogHTML[2].chapterHTML`),
          }
        ],
        blogCoverPhoto: "offer-image",
        blogAdditionalPhotos: null,
        offer: true
      },
      {
        blogID: 3,
        blogTitle: i18n.t(`blogPostArray[3].blogTitle`),
        blogPreview: i18n.t(`blogPostArray[3].blogPreview`),
        blogHTML: [
          {
            chapterTitle: i18n.t(`blogPostArray[3].blogHTML[0].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[3].blogHTML[0].chapterHTML`),
          },
        ],
        blogCoverPhoto: "aboutus-image",
        blogAdditionalPhotos: null,
        aboutUs: true
      },
      {
        blogID: 4,
        blogTitle: i18n.t(`blogPostArray[4].blogTitle`),
        blogPreview: i18n.t(`blogPostArray[4].blogPreview`),
        blogCoverPhoto: "contact-image",
        blogAdditionalPhotos: null,
        contact: true
      },
      {
        blogID: 5,
        blogTitle: i18n.t(`blogPostArray[5].blogTitle`),
        blogPreview: i18n.t(`blogPostArray[5].blogPreview`),
        blogHTML: [
          {
            chapterTitle: i18n.t(`blogPostArray[5].blogHTML[0].chapterTitle`),
            chapterHTML: i18n.t(`blogPostArray[5].blogHTML[0].chapterHTML`),
          },
        ],
        blogCoverPhoto: "home-image",
        blogAdditionalPhotos: null,
        impressum: true
      },

    ],
  },
  getters: {
    // priorityFeed: (state) => (blogID) => {
    //   return state.blogPostArray.find(blog => blog.blogID === blogID);
    // },
    blogPostFeed(state) {
      return state.blogPostArray.slice(1, 5);
    },
    contactCardsAktivitas(state) {
      return state.contactCardsArray.slice(0, 5);
    },

    getLanguage: state => state.language
  },
  mutations: {
    changeLanguage (state, data) {
      this.state.language = data
    }
  },
  actions: {
    changeLanguage: ({ commit }, data) => commit('changeLanguage', data)
  },
  modules: {
  }
})
