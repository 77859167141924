<template>
  <footer>
      <div class="band">
        <img class="band-navbar" src="../assets/navbarImages/band.jpg"/>
      </div>
      <div class="container">
        <div class="column contact-info">
          <p class="footer-title">Akademia Językowa Fulgur</p>
          <p class="footer-title-contact-name">Damian J. Schwider</p>
          <p class="footer-title-contact">ul. Wolności 246</p>
          <p class="footer-title-contact">41-800 Zabrze</p>
          <p class="footer-title-contact">+48 508 404 970</p>
          <p class="footer-title-contact-email">akademia.jezykowa.schwider@gmail.com</p>
        </div>
        <div class="column social-media">
          <p class="footer-title">Jesteśmy także<br>na social mediach</p>
          <ul>
            <a href="https://twitter.com/AJFulgur" target="_blank"><twitter class="svg-icon svg-orange"/></a>
            <a href="https://www.instagram.com/akademiajezykowafulgur/" target="_blank"><instagram class="svg-icon svg-white"/></a>
            <a href="https://www.facebook.com/akademiajezykowafulgur" target="_blank"><facebook class="svg-icon svg-white"/></a>
          </ul>
        </div>
        <div class="column logo-impressum">
          <img class="brand-logo-footer" alt="salia-wappen" src="../assets/navbarImages/salia-wappen.png"/>
          <router-link class="link link-footer-small last-elem" :to="`/${$i18n.locale}/impressum`">{{ $t('footer.impressum') }}</router-link>
          <p class="watermark">© 2023 Rafał Wawrzynek</p>
        </div>
      </div>
  </footer>
</template>


<script>
import instagram from "../assets/Icons/instagram-brands.svg";
import facebook from "../assets/Icons/facebook-brands.svg";
import twitter from "../assets/Icons/x-brands.svg";

export default {
  name: "footer",
  components: {
    instagram,
    facebook,
    twitter
  },
};
</script>


<style lang="scss" scoped>
footer {
  margin-top: auto;
  padding: 0px 0px;
  background-color: #303030;
  color: white; // Biały kolor tekstu

  .band {
    padding-bottom: 25px;
    display: flex;
    
    img {
      width: 100%;
      height: 20px;
      margin: auto;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    text-align: center; // Wyśrodkowanie tekstu na urządzeniach mobilnych

    @media (min-width: 1200px) { // Zmiana punktu przejścia dla desktopu
      flex-direction: row;
      justify-content: space-between;
      text-align: left; // Wyjustowanie tekstu na desktopie
    }

    .column {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-bottom: 20px; // Odstęp między kolumnami na urządzeniach mobilnych

      @media (min-width: 1200px) { // Stylowanie dla desktopu
        width: 30%; // Skalowanie kolumn
        margin-bottom: 0;
        text-align: left; // Wyjustowanie tekstu w kolumnach
      }
    }

    .contact-info, .social-media {
      align-items: center; // Wyśrodkowanie w przypadku urządzeń mobilnych
      margin-bottom: 40px;
    }

    .social-media {
      ul {
        display: flex;
        list-style: none;
        padding: 0;
        justify-content: center; // Wyśrodkowanie ikon
        margin-top: 20px; // Dodatkowy odstęp między tytułem a ikonami

        a {
          margin: 0 10px;
        }
      }
    }

    .logo-impressum {
      align-items: center;
      text-align: center;
      margin-bottom: 20px; // Odstęp między 'impressum' a znakiem wodnym

      .brand-logo-footer {
        width: 100px; // Skalowanie logo
        height: auto;
        margin-bottom: 20px;
      }

      .link {
        color: orange;
        text-decoration: none;
        margin-bottom: 10px;
        &:hover {
          color: white;
        }
      }
    }

    .footer-title, .footer-title-contact, .footer-title-contact-name, .footer-title-contact-email, .watermark {
      margin: 3px 0; // Zmniejszenie odstępów między liniami
      white-space: nowrap; // Zapobiega łamaniu linii tekstu
    }

    .footer-title {
      font-size: 20px;
      text-transform: uppercase;
      text-align: center;
    }

    .svg-icon {
      width: 40px;
      height: 40px;
    }

    .svg-orange {
      color: orange;
    }

    .svg-white {
      color: #f1f1f1;
    }

    .watermark {
      font-size: 15px;
    }
  }
}
</style>




